/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ProgressStatus } from "./features/api/types";
import { AlloV2, createEthersTransactionSender, createPinataIpfsUploader, createWaitForIndexerSyncTo, getChainById } from "common";
import { useCartStorage } from "./store";
import { useAttestationStore } from "./attestationStore";
import { getContract, InternalRpcError, parseAbi, parseUnits, SwitchChainError, UserRejectedRequestError, zeroAddress } from "viem";
import { encodeQFVotes, encodedQFAllocation, signPermit2612, signPermitDai } from "./features/api/voting";
import { groupBy, uniq } from "lodash-es";
import { getEnabledChains } from "./app/chainConfig";
import { getPermitType } from "common/dist/allo/voting";
import { getConfig } from "common/src/config";
import { getEthersProvider, getEthersSigner } from "./app/wagmi";
const isV2 = getConfig().allo.version === "allo-v2";
const defaultProgressStatusForAllChains = Object.fromEntries(Object.values(getEnabledChains()).map(value => [value.id, ProgressStatus.NOT_STARTED]));
export const useCheckoutStore = create()(devtools((set, get) => ({
  permitStatus: defaultProgressStatusForAllChains,
  setPermitStatusForChain: (chain, permitStatus) => set(oldState => ({
    permitStatus: {
      ...oldState.permitStatus,
      [chain]: permitStatus
    }
  })),
  voteStatus: defaultProgressStatusForAllChains,
  setVoteStatusForChain: (chain, voteStatus) => set(oldState => ({
    voteStatus: {
      ...oldState.voteStatus,
      [chain]: voteStatus
    }
  })),
  chainSwitchStatus: defaultProgressStatusForAllChains,
  setChainSwitchStatusForChain: (chain, chainSwitchStatus) => set(oldState => ({
    chainSwitchStatus: {
      ...oldState.chainSwitchStatus,
      [chain]: chainSwitchStatus
    }
  })),
  currentChainBeingCheckedOut: undefined,
  chainsToCheckout: [],
  setChainsToCheckout: chains => {
    set({
      chainsToCheckout: chains
    });
  },
  checkout: async (chainsToCheckout, walletClient, connector, directAllocation) => {
    var _walletClient$account;
    const userAddress = (_walletClient$account = walletClient.account) === null || _walletClient$account === void 0 ? void 0 : _walletClient$account.address;
    const chainIdsToCheckOut = chainsToCheckout.map(chain => chain.chainId);
    const hasDirectAllocation = !!directAllocation;
    get().setChainsToCheckout(uniq([...get().chainsToCheckout, ...chainIdsToCheckOut]));
    const projectsToCheckOut = useCartStorage.getState().projects.filter(project => chainIdsToCheckOut.includes(project.chainId));
    const projectsByChain = groupBy(projectsToCheckOut, "chainId");
    const getVotingTokenForChain = useCartStorage.getState().getVotingTokenForChain;
    const totalDonationPerChain = Object.fromEntries(Object.entries(projectsByChain).map(_ref => {
      let [key, value] = _ref;
      return [Number(key), value.map(project => project.amount).reduce((acc, amount) => acc + parseUnits(amount ? amount : "0", getVotingTokenForChain(Number(key)).decimals), 0n)];
    }));

    /* Main chain loop */
    for (const currentChain of chainsToCheckout) {
      const chainId = currentChain.chainId;
      const deadline = currentChain.permitDeadline;
      const donations = projectsByChain[chainId];
      const isDirectAllocation = hasDirectAllocation && directAllocation.chainId === chainId;
      set({
        currentChainBeingCheckedOut: chainId
      });

      /* Switch to the current chain */
      await switchToChain(chainId, walletClient, get);
      const token = await getVotingTokenForChain(chainId);
      const chain = getChainById(chainId);
      let sig;
      let nonce;
      if (token.address !== zeroAddress) {
        /* Need permit */
        try {
          get().setPermitStatusForChain(chainId, ProgressStatus.IN_PROGRESS);
          const owner = walletClient.account.address;

          /* Get nonce and name from erc20 contract */
          const erc20Contract = getContract({
            address: token.address,
            abi: parseAbi(["function nonces(address) public view returns (uint256)", "function name() public view returns (string)"]),
            client: walletClient
          });
          nonce = await erc20Contract.read.nonces([owner]);
          let tokenName = await erc20Contract.read.name();
          if (getPermitType(token, chainId) === "dai") {
            var _token$permitVersion;
            sig = await signPermitDai({
              walletClient: walletClient,
              spenderAddress: chain.contracts.multiRoundCheckout,
              chainId,
              deadline: BigInt(deadline),
              contractAddress: token.address,
              erc20Name: tokenName,
              ownerAddress: owner,
              nonce,
              permitVersion: (_token$permitVersion = token.permitVersion) !== null && _token$permitVersion !== void 0 ? _token$permitVersion : "1"
            });
          } else {
            var _token$permitVersion2;
            // cUSD is a special case where the token symbol is used for permit instead of the name
            if (chainId === 42220 && token.address.toLowerCase() === "0x765de816845861e75a25fca122bb6898b8b1282a".toLowerCase()) tokenName = "cUSD";
            sig = await signPermit2612({
              walletClient: walletClient,
              value: isDirectAllocation ? totalDonationPerChain[chainId] + directAllocation.amount : totalDonationPerChain[chainId],
              spenderAddress: chain.contracts.multiRoundCheckout,
              nonce,
              chainId,
              deadline: BigInt(deadline),
              contractAddress: token.address,
              erc20Name: tokenName,
              ownerAddress: owner,
              permitVersion: (_token$permitVersion2 = token.permitVersion) !== null && _token$permitVersion2 !== void 0 ? _token$permitVersion2 : "1"
            });
          }
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_SUCCESS);
        } catch (e) {
          if (!(e instanceof UserRejectedRequestError)) {
            console.error("permit error", e, {
              donations,
              chainId,
              tokenAddress: token.address
            });
          }
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_ERROR);
          return;
        }
        if (!sig) {
          get().setPermitStatusForChain(chainId, ProgressStatus.IS_ERROR);
          return;
        }
      } else {
        /** When voting via native token, we just set the permit status to success */
        get().setPermitStatusForChain(chainId, ProgressStatus.IS_SUCCESS);
      }
      try {
        get().setVoteStatusForChain(chainId, ProgressStatus.IN_PROGRESS);

        /* Group donations by round */
        const groupedDonations = groupBy(donations.map(d => ({
          ...d,
          roundId: d.roundId
        })), "roundId");
        const groupedEncodedVotes = {};
        for (const roundId in groupedDonations) {
          groupedEncodedVotes[roundId] = isV2 ? encodedQFAllocation(token, groupedDonations[roundId]) : encodeQFVotes(token, groupedDonations[roundId]);
        }
        const groupedAmounts = {};
        for (const roundId in groupedDonations) {
          groupedAmounts[roundId] = groupedDonations[roundId].reduce((acc, donation) => acc + parseUnits(donation.amount, token.decimals), 0n);
        }
        const amountArray = [];
        for (const roundId in groupedDonations) {
          groupedDonations[roundId].map(donation => {
            amountArray.push(parseUnits(donation.amount, token.decimals));
          });
        }
        const alloInstance = new AlloV2({
          chainId,
          transactionSender: createEthersTransactionSender(await getEthersSigner(connector, chainId), getEthersProvider(chainId)),
          ipfsUploader: createPinataIpfsUploader({
            token: getConfig().pinata.jwt,
            endpoint: `${getConfig().pinata.baseUrl}/pinning/pinFileToIPFS`
          }),
          waitUntilIndexerSynced: createWaitForIndexerSyncTo(`${getConfig().dataLayer.gsIndexerEndpoint}/graphql`)
        });
        const receipt = await alloInstance.donate(chainId, token, groupedEncodedVotes, isV2 ? amountArray : groupedAmounts, totalDonationPerChain[chainId], sig ? {
          sig,
          deadline,
          nonce: nonce
        } : undefined, isDirectAllocation ? directAllocation : undefined);
        if (receipt.status === "reverted") {
          throw new Error("donate transaction reverted", {
            cause: {
              receipt
            }
          });
        }

        /* Remove checked out projects from cart */
        donations.forEach(donation => {
          useCartStorage.getState().remove(donation);
        });
        set(oldState => ({
          voteStatus: {
            ...oldState.voteStatus,
            [chainId]: ProgressStatus.IS_SUCCESS
          }
        }));
        set({
          checkedOutProjects: [...get().checkedOutProjects, ...donations]
        });
        useAttestationStore.getState().addCheckedOutTransaction(receipt.transactionHash, userAddress);
      } catch (error) {
        let context = {
          chainId,
          donations,
          token
        };
        if (error instanceof Error) {
          context = {
            ...context,
            error: error.message,
            cause: error.cause
          };
        }

        // do not log user rejections
        if (!(error instanceof UserRejectedRequestError)) {
          console.error("donation error", error, context);
        }
        get().setVoteStatusForChain(chainId, ProgressStatus.IS_ERROR);
        throw error;
      }
    }
    /* End main chain loop*/
  },
  checkedOutProjects: [],
  getCheckedOutProjects: () => {
    return get().checkedOutProjects;
  },
  setCheckedOutProjects: newArray => {
    set({
      checkedOutProjects: newArray
    });
  }
})));

/** This function handles switching to a chain
 * if the chain is not present in the wallet, it will add it, and then switch */
async function switchToChain(chainId, walletClient, get) {
  get().setChainSwitchStatusForChain(chainId, ProgressStatus.IN_PROGRESS);
  const nextChainData = getEnabledChains().find(chain => chain.id === chainId);
  if (!nextChainData) {
    get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
    throw "next chain not found";
  }
  try {
    /* Try switching normally */
    await walletClient.switchChain({
      id: chainId
    });
  } catch (e) {
    if (e instanceof UserRejectedRequestError) {
      console.log("Rejected!");
      get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
      return;
    } else if (e instanceof SwitchChainError || e instanceof InternalRpcError) {
      console.log("Chain not added yet, adding", {
        e
      });
      /** Chain might not be added in wallet yet. Request to add it to the wallet */
      try {
        var _nextChainData$tokens, _nextChainData$tokens2, _nextChainData$tokens3;
        await walletClient.addChain({
          chain: {
            id: nextChainData.id,
            name: nextChainData.name,
            blockExplorers: {
              default: {
                name: `${nextChainData.prettyName} Explorer`,
                url: nextChainData.blockExplorer
              }
            },
            nativeCurrency: {
              decimals: (_nextChainData$tokens = nextChainData.tokens.find(token => token.address === zeroAddress)) === null || _nextChainData$tokens === void 0 ? void 0 : _nextChainData$tokens.decimals,
              name: (_nextChainData$tokens2 = nextChainData.tokens.find(token => token.address === zeroAddress)) === null || _nextChainData$tokens2 === void 0 ? void 0 : _nextChainData$tokens2.code,
              symbol: (_nextChainData$tokens3 = nextChainData.tokens.find(token => token.address === zeroAddress)) === null || _nextChainData$tokens3 === void 0 ? void 0 : _nextChainData$tokens3.code
            },
            rpcUrls: {
              default: {
                http: [nextChainData.rpc]
              },
              public: {
                http: [nextChainData.rpc]
              }
            }
          }
        });
      } catch (e) {
        get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
        return;
      }
    } else {
      console.log("unhandled error when switching chains", {
        e
      });
      get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_ERROR);
      return;
    }
  }
  get().setChainSwitchStatusForChain(chainId, ProgressStatus.IS_SUCCESS);
}