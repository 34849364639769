var _process$env$REACT_AP;
import "@rainbow-me/rainbowkit/styles.css";
import { QueryClient } from "@tanstack/react-query";
import { getDefaultConfig } from "@rainbow-me/rainbowkit";
import { allNetworks, mainnetNetworks } from "common/src/chains";
import { getClient, getConnectorClient } from "@wagmi/core";
import { providers } from "ethers";
export const allChains = process.env.REACT_APP_ENV === "development" ? allNetworks : mainnetNetworks;

/* TODO: remove hardcoded value once we have environment variables validation */
const projectId = (_process$env$REACT_AP = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID) !== null && _process$env$REACT_AP !== void 0 ? _process$env$REACT_AP : "2685061cae0bcaf2b244446153eda9e1";
export const config = getDefaultConfig({
  appName: "Gitcoin Explorer",
  projectId,
  chains: [...allChains]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
});
const queryClient = new QueryClient();
export function clientToProvider(client) {
  var _chain$contracts;
  const {
    chain,
    transport
  } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: (_chain$contracts = chain.contracts) === null || _chain$contracts === void 0 || (_chain$contracts = _chain$contracts.ensRegistry) === null || _chain$contracts === void 0 ? void 0 : _chain$contracts.address
  };
  if (transport.type === "fallback") return new providers.FallbackProvider(transport.transports.map(_ref => {
    let {
      value
    } = _ref;
    return new providers.JsonRpcProvider(value === null || value === void 0 ? void 0 : value.url, network);
  }));
  return new providers.JsonRpcProvider(transport.url, network);
}

/** Action to convert a viem Public Client to an ethers.js Provider. */
export function getEthersProvider(chainId) {
  const client = getClient(config, {
    chainId
  });
  if (!client) return;
  return clientToProvider(client);
}
export function clientToSigner(client) {
  var _chain$contracts2;
  const {
    account,
    chain,
    transport
  } = client;
  const network = {
    chainId: chain.id,
    name: chain.name,
    ensAddress: (_chain$contracts2 = chain.contracts) === null || _chain$contracts2 === void 0 || (_chain$contracts2 = _chain$contracts2.ensRegistry) === null || _chain$contracts2 === void 0 ? void 0 : _chain$contracts2.address
  };
  const provider = new providers.Web3Provider(transport, network);
  const signer = provider.getSigner(account.address);
  return signer;
}

/** Action to convert a Viem Client to an ethers.js Signer. */
export async function getEthersSigner(connector, chainId) {
  const client = await getConnectorClient(config, {
    chainId,
    connector
  });
  return clientToSigner(client);
}
export default queryClient;