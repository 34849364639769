import useSWR from "swr";
import { allChains } from "../../../app/wagmi";
export function useProjects(params, dataLayer) {
  return useSWR(["projects", params], async () => {
    var _await$dataLayer$getP;
    const validatedParams = {
      first: params.first,
      offset: params.offset,
      chainIds: allChains.map(chain => chain.id)
    };
    return (_await$dataLayer$getP = await dataLayer.getPaginatedProjects(validatedParams)) !== null && _await$dataLayer$getP !== void 0 ? _await$dataLayer$getP : undefined;
  });
}
export function useProjectsBySearchTerm(params, dataLayer) {
  return useSWR(["projects", params], async () => {
    var _await$dataLayer$getP2;
    const validatedParams = {
      searchTerm: params.searchTerm,
      first: params.first,
      offset: params.offset,
      chainIds: allChains.map(chain => chain.id)
    };
    return (_await$dataLayer$getP2 = await dataLayer.getProjectsBySearchTerm(validatedParams)) !== null && _await$dataLayer$getP2 !== void 0 ? _await$dataLayer$getP2 : undefined;
  });
}