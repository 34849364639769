import { useEffect, useMemo, useState } from "react";
import { calculateDonationPeriod, isValidStringDate } from "../utils";
import { useRoundById } from "../../../../../../context/RoundContext";
// Returns undefined if the application is not stakable or if the donation period is not valid
export const useDonationPeriod = _ref => {
  let {
    chainId,
    roundId,
    refreshInterval = 5 * 60 * 1000
  } = _ref;
  const {
    round
  } = useRoundById(chainId, roundId);
  const hasValidDonationDates = useMemo(() => {
    if (!round) return false;
    const {
      roundStartTime: donationsStartTime,
      roundEndTime: donationsEndTime
    } = round;
    return isValidStringDate(donationsStartTime.toISOString()) && isValidStringDate(donationsEndTime.toISOString());
  }, [round]);
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      if (hasValidDonationDates) setCurrentTime(new Date());
    }, refreshInterval);
    return () => clearInterval(interval);
  }, [hasValidDonationDates, refreshInterval]);
  const {
    isDonationPeriod,
    timeToDonationStart,
    timeToDonationEnd
  } = useMemo(() => {
    var _round$roundStartTime, _round$roundEndTime$t;
    return calculateDonationPeriod({
      roundDonationPeriod: {
        roundStartTime: (_round$roundStartTime = round === null || round === void 0 ? void 0 : round.roundStartTime.toISOString()) !== null && _round$roundStartTime !== void 0 ? _round$roundStartTime : "",
        roundEndTime: (_round$roundEndTime$t = round === null || round === void 0 ? void 0 : round.roundEndTime.toISOString()) !== null && _round$roundEndTime$t !== void 0 ? _round$roundEndTime$t : ""
      },
      currentTime,
      hasValidDonationDates
    });
  }, [round, currentTime, hasValidDonationDates]);
  return {
    isDonationPeriod,
    timeToDonationStart,
    timeToDonationEnd
  };
};