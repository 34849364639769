import { useQuery } from "@tanstack/react-query";
export const useRoundStakingSummary = (alloPoolId, chainId, isStakableRound) => {
  const query = useQuery({
    enabled: isStakableRound,
    queryKey: ["poolSummary", alloPoolId, chainId],
    queryFn: () => getPoolSummary(alloPoolId, Number(chainId))
  });
  return {
    data: query.data,
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    refetch: query.refetch
  };
};
const GET = async url => {
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    }
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(`Error: ${response.status} - ${errorData.message || "Unknown error"}`);
  }
  return response.json();
};
export async function getPoolSummary(alloPoolId, chainId) {
  try {
    const url = `${process.env.REACT_APP_STAKING_HUB_ENDPOINT}/api/pools/${chainId}/${alloPoolId}/summary`;
    const response = await GET(url);
    return response;
  } catch (error) {
    console.error("Error fetching pool info and stakes:", error);
    throw error;
  }
}